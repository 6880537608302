<template>
    <ValidationObserver ref="observe">
        <b-form>
            <ek-dialog
                ref="addDialog"
                title="إضافة إستاذ"
                size="md"
                placeholder="ابحث عن استاذ محدد"
                btnText="اضافة إستاذ"
                @ok="submit()"
                @close="reset"
                @search="setSearch"
            >
                <template #body>
                    <ek-input-text
                        :rules="[
                            {
                                type: 'required',
                                message: 'الاسم و الكنية مطلوب',
                            },
                        ]"
                        name="name"
                        placeholder="أدخل الاسم و الكنية "
                        label="الاسم و الكنية"
                        v-model="teacherDto.name"
                    ></ek-input-text>

                    <ek-input-text
                        :rules="[
                            {
                                type: 'email',
                                message: 'يرجى ادخال بريد الكتروني صحيح',
                            },
                        ]"
                        name="email"
                        placeholder="ادخل البريد الالكتروني"
                        label="البريد الالكتروني"
                        v-model="teacherDto.email"
                    ></ek-input-text>

                    <ek-input-text
                        :rules="[
                            { type: 'required', message: 'كلمة المرور مطلوبة' },
                            {
                                type: 'min:8',
                                message:
                                    'كلمة المرور يجب ان تحوي 8 محارف على الاقل',
                            },
                        ]"
                        name="password"
                        placeholder="أدخل  كلمة السر"
                        label="كلمة السر"
                        v-model="teacherDto.password"
                    ></ek-input-text>

                    <ek-input-text
                        :rules="[
                            { type: 'required', message: 'رقم الهاتف مطلوب' },
                            { type: 'mobile', message: 'رقم الهاتف غير صالح' },
                        ]"
                        name="phoneNumber"
                        placeholder="أدخل الهاتف"
                        label="رقم الهاتف"
                        v-model="teacherDto.phoneNumber"
                    ></ek-input-text>

                    <ek-input-text
                        name="rate"
                        placeholder="أدخل النسبة"
                        label="نسبة الاستاذ"
                        v-model="rateDto.teacherRate"
                    ></ek-input-text>

                    <ek-input-image
                        ref="imgFile"
                        label=" صورة الأستاذ "
                        name="صور "
                        @input="updateSubFile($event)"
                    >
                        <h5>انقر لتحميل الصورة</h5>
                    </ek-input-image>
                    <ek-input-textarea
                        v-model="teacherDto.description"
                        name="وصف الأستاذ"
                        placeholder="ادخل وصف الأستاذ "
                        label="الوصف"
                        :rules="[
                            {
                                type: 'required',
                                message: 'وصف الأستاذ مطلوب',
                            },
                        ]"
                    ></ek-input-textarea>
                </template>
            </ek-dialog>
        </b-form>
    </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
    components: {
        ValidationObserver,
    },
    computed: {
        ...mapState({
            teacherDto: (state) => state.teachers.teacherDto,
            rateDto: (state) => state.settings.rateDto,
        }),
    },
    methods: {
        updateSubFile($event) {
            this.teacherDto.Image = $event.file;
        },
        ...mapActions(["addTeachers"]),
        ...mapMutations(["Reset_Teacher_Dto"]),
        submit() {
            this.$refs.observe.validate().then((success) => {
                if (success) {
                    this.addTeachers({
                        name: this.teacherDto.name,
                        email: this.teacherDto.email,
                        password: this.teacherDto.password,
                        rate: this.rateDto.teacherRate,
                        phoneNumber: this.teacherDto.phoneNumber,
                        Discription: this.teacherDto.description,
                        Image: this.teacherDto.Image,


                    });
                    this.$refs.addDialog.close();
                    this.$refs.observe.reset();
                }
            });
        },
        reset() {
            this.$refs["observe"].reset();
            Object.assign(this.teacherDto, {
                name: "",
                email: "",
                rate: "",
                password: "",
                phoneNumber: "",
            });
        },
        setSearch(query) {
            this.$store.commit("Set_Search_Dto", {
                keys: ["name", "subCount", "isBlocked", "unitsCount"],
                query,
            });
        },
        open() {
            this.Reset_Teacher_Dto();
            this.$refs.addDialog.open();
        },
    },
};
</script>
